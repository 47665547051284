import OnOfficeEstate from "./estate";
import { SortOption } from "./sort";

export const BUY = "kauf",
  RENT = "miete";

export type Range = {
  min: number;
  max: number;
};

export enum FilterAction {
  KindSelection = "KIND_SELECTION",
  SaleSelection = "SALE_SELECTION",
  TypeSelection = "TYPE_SELECTION",
  M2Start = "M2_START",
  TotalM2Start = "TOTAL_M2_START",
  RoomCntStart = "ROOM_CNT_START",
  PriceStart = "PRICE_START",
  RentStart = "RENT_START",
  PropertyM2Start = "PROPERTY_M2_START",
  SortOption = "SORT_OPTION",
  Total = "TOTAL",
  CurrentPage = "CURRENT_PAGE",
  EstatePage = "MIETE",
  ResetFilters = "RESET_FILTERS",
  LocationSelection = "LOCATION_SELECTION",
}
export interface FilterState {
  fields;
  kindOptions: string[];
  kindSelection: string[];
  saleOptions: string[];
  saleSelection: string[];
  typeOptions: string[];
  typeSelection: string[];
  m2Range: Range;
  m2Start: number[];
  totalM2Range: Range;
  totalM2Start: number[];
  roomCntRange: Range;
  roomCntStart: number[];
  priceRange: Range;
  priceStart: number[];
  rentRange: Range;
  rentStart: number[];
  propertyM2Range: Range;
  propertyM2Start: number[];
  sortOptions: SortOption[];
  sortOption: SortOption;
  initialEstates: OnOfficeEstate[];
  filteredEstates: OnOfficeEstate[];
  total: number;
  currentPage: number;
  estatePage: OnOfficeEstate[];
  locationOptions: string[];
  locationSelection: string[];
}

export default Range;
