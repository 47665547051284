import { Link } from "gatsby";
import { Nav } from "react-bootstrap";
import React from "react";

interface PaginationProps {
  total: number;
  paginate: (page: number) => void;
  current: number;
}

export const PER_PAGE = 999; // alle Immobilien auf einer Seite (Originalwert: 21)

const Pagination = (props: PaginationProps) => {
  const showPagination: boolean = props.total > PER_PAGE;
  const pageNumbers = [];
  if (showPagination) {
    for (let i = 1; i <= Math.ceil(props.total / PER_PAGE); i++) {
      pageNumbers.push(i);
    }

    return (
      <Nav className="pagination-nav">
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <Link
              to="#"
              key={number}
              className={props.current === number ? "active-page" : undefined}
              onClick={() => props.paginate(number)}
            >
              {number}
            </Link>
          ))}
        </ul>
      </Nav>
    );
  } else {
    return null;
  }
};

export default Pagination;
