import { FilterAction, FilterState } from "../../types/filter";

import { PER_PAGE } from "../estates/Pagination";
import { isEmpty } from "../../utils/utils";
import { sortEstates } from "../../types/sort";

function filterSortEstates(state: FilterState) {
  const kind =
      state.kindSelection.length === 0
        ? state.kindOptions
        : state.kindSelection,
    sale =
      state.saleSelection.length === 0
        ? state.saleOptions
        : state.saleSelection,
    type =
      state.typeSelection.length === 0
        ? state.typeOptions
        : state.typeSelection,
    location =
      state.locationSelection.length === 0
        ? state.locationOptions
        : state.locationSelection;

  return sortEstates(
    state.initialEstates.filter((obj) => {
      const m2 = Math.floor(Number(obj.wohnflaeche)),
        roomCnt = Math.floor(Number(obj.anzahl_zimmer)),
        price = Math.floor(Number(obj.kaufpreis)),
        rent = Math.floor(Number(obj.kaltmiete)),
        propertyM2 = Math.floor(Number(obj.grundstuecksflaeche));
      return (
        kind.includes(obj.objektart) &&
        sale.includes(obj.vermarktungsart) &&
        type.includes(obj.objekttyp) &&
        location.includes(obj.ort) &&
        m2 >=
          ([
            "zimmer",
            "haus",
            "wohnung",
            "grundstueck",
            "hallen_lager_prod",
          ].filter((value) => kind.includes(value)).length > 0
            ? state.m2Start[0]
            : 0) &&
        m2 <= state.m2Start[1] &&
        roomCnt >=
          ([
            "zimmer",
            "haus",
            "wohnung",
            "buero_praxen",
            "einzelhandel",
            "gastgewerbe",
            "hallen_lager_prod",
            "land_und_forstwirtschaft",
            "freizeitimmbilien_gewerblich",
            "freizeitimmbilien_gewerblich",
            "sonstige",
            "parken",
            "zinshaus_renditeobjekt",
          ].filter((value) => kind.includes(value)).length > 0
            ? state.roomCntStart[0]
            : 0) &&
        roomCnt <= state.roomCntStart[1] &&
        price >=
          Number(
            (["kauf"].filter((value) => sale.includes(value)).length > 0
              ? state.priceStart[0]
              : 0
            )
              .toString()
              .replace(/\./g, "")
          ) &&
        price <= Number(state.priceStart[1].toString().replace(/\./g, "")) &&
        rent >=
          Number(
            (["miete"].filter((value) => sale.includes(value)).length > 0
              ? state.rentStart[0]
              : 0
            )
              .toString()
              .replace(/\./g, "")
          ) &&
        rent <= Number(state.rentStart[1].toString().replace(/\./g, "")) &&
        propertyM2 >=
          Number(
            ([
              "haus",
              "wohnung",
              "grundstueck",
              "einzelhandel",
              "hallen_lager_prod",
              "land_und_forstwirtschaft",
            ].filter((value) => sale.includes(value)).length > 0
              ? state.propertyM2Start[0]
              : 0
            )
              .toString()
              .replace(/\./g, "")
          ) &&
        propertyM2 <=
          Number(state.propertyM2Start[1].toString().replace(/\./g, ""))
      );
    }),
    state.sortOption.key
  );
}

function setEstatePage(state: FilterState) {
  return state.filteredEstates.slice(
    state.currentPage * PER_PAGE - PER_PAGE,
    state.currentPage * PER_PAGE
  );
}

export function reducer(state: FilterState, action) {
  switch (action.type) {
    case FilterAction.KindSelection:
      const kindSelectionState = {
          ...state,
          kindSelection: action.value,
          currentPage: 1,
        },
        kindSelectionStateUpd = {
          ...kindSelectionState,
          filteredEstates: filterSortEstates(kindSelectionState),
        };
      return {
        ...kindSelectionStateUpd,
        total: kindSelectionStateUpd.filteredEstates.length,
        estatePage: setEstatePage(kindSelectionStateUpd),
      };
    case FilterAction.SaleSelection:
      const saleSelectionState = {
          ...state,
          saleSelection: action.value,
          currentPage: 1,
        },
        saleSelectionStateUpd = {
          ...saleSelectionState,
          filteredEstates: filterSortEstates(saleSelectionState),
        };
      return {
        ...saleSelectionStateUpd,
        total: saleSelectionStateUpd.filteredEstates.length,
        estatePage: setEstatePage(saleSelectionStateUpd),
      };
    case FilterAction.TypeSelection:
      const typeSelectionState = {
          ...state,
          typeSelection: action.value,
          currentPage: 1,
        },
        typeSelectionStateUpd = {
          ...typeSelectionState,
          filteredEstates: filterSortEstates(typeSelectionState).filter(
            (e) => !isEmpty(e.objekttyp)
          ),
        };
      return {
        ...typeSelectionStateUpd,
        total: typeSelectionStateUpd.filteredEstates.length,
        estatePage: setEstatePage(typeSelectionStateUpd),
      };
    case FilterAction.M2Start:
      const m2StartState = {
          ...state,
          m2Start: action.value,
          currentPage: 1,
        },
        m2StartStateUpd = {
          ...m2StartState,
          filteredEstates: filterSortEstates(m2StartState),
        };
      return {
        ...m2StartStateUpd,
        total: m2StartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(m2StartStateUpd),
      };
    case FilterAction.TotalM2Start:
      const totalM2StartState = {
          ...state,
          totalM2Start: action.value,
          currentPage: 1,
        },
        totalM2StartStateUpd = {
          ...totalM2StartState,
          filteredEstates: filterSortEstates(totalM2StartState),
        };
      return {
        ...totalM2StartStateUpd,
        total: totalM2StartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(totalM2StartStateUpd),
      };
    case FilterAction.RoomCntStart:
      const roomCntStartState = {
          ...state,
          roomCntStart: action.value,
          currentPage: 1,
        },
        roomCntStartStateUpd = {
          ...roomCntStartState,
          filteredEstates: filterSortEstates(roomCntStartState),
        };
      return {
        ...roomCntStartStateUpd,
        total: roomCntStartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(roomCntStartStateUpd),
      };
    case FilterAction.PriceStart:
      const priceStartState = {
          ...state,
          priceStart: action.value,
          currentPage: 1,
        },
        priceStartStateUpd = {
          ...priceStartState,
          filteredEstates: filterSortEstates(priceStartState),
        };
      return {
        ...priceStartStateUpd,
        total: priceStartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(priceStartStateUpd),
      };
    case FilterAction.RentStart:
      const rentStartState = {
          ...state,
          rentStart: action.value,
          currentPage: 1,
        },
        rentStartStateUpd = {
          ...rentStartState,
          filteredEstates: filterSortEstates(rentStartState),
        };
      return {
        ...rentStartStateUpd,
        total: rentStartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(rentStartStateUpd),
      };
    case FilterAction.PropertyM2Start:
      const propertyM2StartState = {
          ...state,
          propertyM2Start: action.value,
          currentPage: 1,
        },
        propertyM2StartStateUpd = {
          ...propertyM2StartState,
          filteredEstates: filterSortEstates(propertyM2StartState),
        };
      return {
        ...propertyM2StartStateUpd,
        total: propertyM2StartStateUpd.filteredEstates.length,
        estatePage: setEstatePage(propertyM2StartStateUpd),
      };
    case FilterAction.SortOption:
      const sortOptionState = {
          ...state,
          sortOption: action.value,
          currentPage: 1,
        },
        sortOptionStateUpd = {
          ...sortOptionState,
          filteredEstates: filterSortEstates(sortOptionState),
        };
      return {
        ...sortOptionStateUpd,
        total: sortOptionStateUpd.filteredEstates.length,
        estatePage: setEstatePage(sortOptionStateUpd),
      };
    case FilterAction.CurrentPage:
      const currentPageState = {
          ...state,
          currentPage: action.value,
        },
        currentPageStateUpd = {
          ...currentPageState,
          filteredEstates: filterSortEstates(currentPageState),
        };
      return {
        ...currentPageStateUpd,
        total: currentPageStateUpd.filteredEstates.length,
        estatePage: setEstatePage(currentPageStateUpd),
      };
    case FilterAction.ResetFilters:
      const resetFiltersState = {
        ...state,
        kindSelection: [],
        saleSelection: [],
        typeSelection: [],
        locationSelection: [],
        m2Start: [state.m2Range.min, state.m2Range.max],
        roomCntStart: [state.roomCntRange.min, state.roomCntRange.max],
        priceStart: [state.priceRange.min, state.priceRange.max],
        rentStart: [state.rentRange.min, state.rentRange.max],
        propertyM2Start: [state.propertyM2Range.min, state.propertyM2Range.max],
        filteredEstates: state.initialEstates,
        total: state.initialEstates.length,
        currentPage: 1,
      };
      return {
        ...resetFiltersState,
        estatePage: setEstatePage(resetFiltersState),
      };
    case FilterAction.LocationSelection:
      const locationSelectionState = {
            ...state,
            locationSelection: action.value,
            currentPage: 1,
          },
          locationSelectionStateUpd = {
            ...locationSelectionState,
            filteredEstates: filterSortEstates(locationSelectionState),
          };
      return {
        ...locationSelectionStateUpd,
        total: locationSelectionStateUpd.filteredEstates.length,
        estatePage: setEstatePage(locationSelectionStateUpd),
      };
    default:
      throw new Error(action.type + ": Bad Action Type");
  }
}
