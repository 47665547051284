import { BUY, RENT } from "../types/filter";

export const isNoValue = (value) =>
  value === null || value === undefined || value.length === 0;

export const isEmpty = (value) =>
  isNoValue(value) ||
  (value.length > 0 &&
    isNoValue(value[0]) &&
    value.every((val, i, arr) => val === arr[0]));

export const isZero = (value) => Number(value) === 0;

export const toValue = (field, value) => (isEmpty(field) ? value : field);

export const average = (list) =>
  isEmpty(list) ? list : list.reduce((prev, curr) => prev + curr) / list.length;

export const distinct = (list) =>
  isEmpty(list) ? list : list.filter((v, i, a) => a.indexOf(v) === i);

export const minNumber = (list) =>
  isEmpty(list) ? list : Math.min(...list.map((s) => Number(s)));

export const maxNumber = (list) =>
  isEmpty(list) ? list : Math.max(...list.map((s) => Number(s)));

export const toEuro = (amount) =>
  isEmpty(amount)
    ? amount
    : new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(Number(amount.replace(",", ".")));

export const toDecimal = (amount) =>
  isEmpty(amount)
    ? amount
    : new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumSignificantDigits: 3,
      }).format(Number(amount));

export const toFNumber = (amount) =>
  isEmpty(amount)
    ? amount
    : new Intl.NumberFormat("de-DE", {
        maximumSignificantDigits: 3,
      }).format(Number(amount));

export const toTitleCase = (phrase) =>
  isEmpty(phrase)
    ? phrase
    : phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

export const toGerman = (phrase) => {
  switch (phrase) {
    case "buy":
      return BUY;
    case "rent":
      return RENT;
    default:
      return phrase;
  }
};

export const toYesOrNo = (digit) =>
  isEmpty(digit) || Number(digit) > 1
    ? digit
    : Number(digit) === 1
    ? "Ja"
    : Number(digit) === 0
    ? "Nein"
    : digit;

export const commaSeparate = (list) =>
  isEmpty(list) ? list : list.filter((el) => !isEmpty(el)).join(", ");

export default average;

export const IsNumberGreaterThanZero = (string) => {
  const numberText = string.replace( /[^\d\.]*/g, '')
  const number = Number(numberText)
  return number > 0
}

export const ConvertStringToEuroIfStringContainsOnlyNumbers = (string) => {
  const numberText = string.replace( /[^\d\.]*/g, '')
  if (numberText.length !== string.length) return string
  if (numberText.length === 0 || string.length===0) return string
  return toEuro(string)
}
