import {graphql, useStaticQuery} from "gatsby";

export default function OnOfficeFieldsQuery() {
    const onOfficeFields = useStaticQuery(graphql`
    query OnOfficeFieldsQuery {
      allFieldsJson {
        edges {
          node {
            elements {
              ind_2038_Feld_ObjTech66 {
                default
                label
                permittedvalues {
                  ind_Schl_3025
                  ind_Schl_3021
                  ind_Schl_3027
                  ind_Schl_3047
                  ind_Schl_3045
                  ind_Schl_3023
                }
              }
              ind_2042_Feld_ObjTech67 {
                label
              }
              ind_2044_Feld_ObjTech68 {
                label
                permittedvalues {
                  ind_Schl_3039
                  ind_Schl_3031                  
                  ind_Schl_3033
                  ind_Schl_3059
                }
              }
              Id {
                label
              }
              abstellraum {
                label
              }
              preisAufAnfrage{
                label
              }
              angeschl_gastronomie {
                permittedvalues {
                  bar
                  hotelrestaurant
                }
                label
              }
              anzahl_badezimmer {
                label
              }
              anzahl_balkone {
                label
              }
              anzahl_betten {
                label
              }
              anzahl_schlafzimmer {
                label
              }
              anzahl_sep_wc {
                label
              }
              anzahl_tagungsraeume {
                label
              }
              anzahl_terrassen {
                type
                label
              }
              anzahl_wohneinheiten {
                label
              }
              anzahl_zimmer {
                label
              }
              ausricht_balkon_terrasse {
                label
                permittedvalues {
                  nordost
                  nordwest
                  suedost
                  suedwest
                  nord
                  ost
                  sued
                  west
                }
              }
              aussen_courtage {
                label
              }
              ausstatt_beschr {
                label
              }
              ausstattungsqualitaet {
                label
                permittedvalues {
                  Einfach
                  Gehoben
                  Luxus
                  Normal
                }
              }
              balkon {
                label
              }
              barrierefrei {
                label
              }
              baujahr {
                label
              }
              bauphase {
                label
                permittedvalues {
                  HausFertigGestellt
                  HausImBau
                  HausInPlanung
                }
              }
              bauweise {
                permittedvalues {
                  fertigteile
                  holz
                  massiv
                  ind_Schl_2975
                }
                label
              }
              bebaubar_mit {
                permittedvalues {
                  ackerland
                  bauerwartungsland
                  bootsstaende
                  buero
                  camping
                  doppelhaus
                  einfamilienhaus
                  einzelhandelgross
                  einzelhandelklein
                  garagen
                  garten
                  gastronomie
                  gewerbe
                  hotel
                  industrie
                  keinebebauung
                  kleingewerbe
                  lager
                  mehrfamilienhaus
                  obstpflanzung
                  parkhaus
                  produktion
                  reihenhaus
                  stellplaetze
                  villa
                  wald
                }
                label
              }
              bebaubar_nach {
                label
                permittedvalues {
                  aussengebiet
                  b_plan
                  bauerwartungsland
                  bauland_ohne_b_plan
                  bebauungsplan
                  kein_bauland
                  laenderspezifisch
                  nachbarbebauung
                }
              }
              befeuerung {
                label
                permittedvalues {
                  luftwp
                  pellet
                  oel
                  gas
                  elektro
                  strom
                  alternativ
                  solar
                  erdwaerme
                  wasser_elektro
                  indMulti1892Select6764
                  pelletheizung
                  fernwaerme
                  indMulti1892Select6766
                  indMulti1978Select7494
                }
              }
              betreutes_wohnen {
                label
              }
              boden {
                permittedvalues {
                  terrakotta
                  marmor
                  fertigparkett
                  doppelboden
                  laminat
                  epoxidharzboden
                  nach_mieterwunsch
                  estrich
                  parkett
                  indMulti1812Select6084
                  stein
                  dielen
                  teppichboden
                  indMulti1812Select6082
                  fliesen
                  beton
                  pvc
                  granit
                  indMulti1856Select6206
                  indMulti1862Select6748
                  indMulti1862Select6750
                  indMulti1892Select6770
                }
                label
              }
              bueroflaeche {
                label
              }
              dachform {
                permittedvalues {
                  flachdach
                  krueppelwalmdach
                  mansarddach
                  pultdach
                  pyramidendach
                  satteldach
                  walmdach
                }
                label
              }
              denkmalgeschuetzt {
                label
              }
              distanz_autobahn {
                label
              }
              distanz_bus {
                label
              }
              distanz_fernbahnhof {
                label
              }
              distanz_flughafen {
                label
              }
              distanz_grundschule {
                label
              }
              distanz_gymnasium {
                label
              }
              distanz_kindergarten {
                label
              }
              distanz_realschule {
                label
              }
              distanz_us_bahn {
                label
              }
              distanz_zentrum {
                label
              }
              dv_verkabelung {
                label
              }
              einliegerwohnung {
                label
              }
              endenergiebedarf {
                label
              }
              endenergiebedarfStrom {
                label
              }
              endenergiebedarfWaerme {
                label
              }
              endenergieverbrauchStrom {
                label
              }
              endenergieverbrauchWaerme {
                label
              }
              energieausweisBaujahr {
                label
              }
              energieausweis_gueltig_bis {
                label
              }
              energieausweistyp {
                permittedvalues {
                  Endenergiebedarf
                  Energieverbrauchskennwert
                  Bedarfsausweis_Gewerbe
                  Verbrauchsausweis_Gewerbe
                  es_besteht_keine_Pflicht_
                  ohne_Energieausweis
                  liegtZurBesichtigungVor
                  ind_Schl_2947
                }
                label
              }
              energietraeger {
                label
                permittedvalues {
                  wasser_elektro
                  solar
                  pelletheizung
                  pellet
                  oel
                  luftwp
                  kohle
                  gas
                  fernwaerme
                  erdwaerme
                  elektro
                  block
                  alternativ
                }
              }
              energieverbrauchskennwert {
                label
              }
              energyClass {
                permittedvalues {
                  A
                  A_
                  B
                  C
                  D
                  E
                  F
                  G
                  H
                }
                label
              }
              erbpacht {
                label
              }
              erschliessung {
                label
                permittedvalues {
                  erschlossen
                  teilerschlossen
                  unerschlossen
                }
              }
              erschliessungskosten {
                label
              }
              erstellt_am {
                label
              }
              etage {
                label
              }
              fahrradraum {
                label
              }
              fahrstuhl {
                label
                permittedvalues {
                  personen
                  lasten
                  kein_fahrstuhl
                }
              }
              gaesteWc {
                label
              }
              gardenhouse {
                label
              }
              gartennutzung {
                label
              }
              gastroflaeche {
                label
              }
              gesamtflaeche {
                label
              }
              gewerbeflaeche {
                label
              }
              gewerbliche_nutzung {
                label
              }
              gfz {
                label
              }
              grundstuecksflaeche {
                label
              }
              grz {
                label
              }
              hausgeld {
                label
              }
              hausnummer {
                label
              }
              haustiere {
                label
                permittedvalues {
                  _0
                  _1
                  _2
                }
              }
              heizkosten {
                label
              }
              heizkosten_in_nebenkosten {
                label
                permittedvalues {
                  J
                  N
                }
              }
              heizungsart {
                label
                permittedvalues {
                  etage
                  ofen
                  zentral
                  fussboden
                  fern
                  indMulti1892Select6768
                  indMulti2102Select7738
                  indMulti2102Select7732
                }
              }
              ind_1810_Feld_ObjAusstattung0 {
                label
                permittedvalues {
                  indMulti1812Select6078
                  indMulti1812Select6076
                  indMulti1812Select6044
                  indMulti1812Select6072
                  indMulti1812Select6074
                  indMulti1812Select6070
                  indMulti1812Select6068
                  indMulti1812Select6080
                  indMulti1812Select6042
                  indMulti1812Select6066
                  indMulti1812Select6064
                  indMulti1812Select6062
                  indMulti1812Select6060
                  indMulti1812Select6058
                  indMulti1812Select6056
                  indMulti1812Select6054
                  indMulti1812Select6052
                  indMulti1812Select6050
                  indMulti1812Select6048
                  indMulti1812Select6046
                  indMulti1816Select6086
                  indMulti1976Select7492
                }
              }
              ind_1812_Feld_ObjAusstattung1 {
                label
              }
              ind_1852_Feld_ObjAusstattung17 {
                label
              }
              ind_1856_Feld_ObjAusstattung18 {
                label
              }
              ind_1860_Feld_ObjAusstattung20 {
                label
              }
              ind_1862_Feld_ObjAusstattung20 {
                label
              }
              ind_1864_Feld_ObjPreise21 {
                label
              }
              ind_1866_Feld_ObjPreise22 {
                type
              }
              ind_1868_Feld_ObjPreise23 {
                label
              }
              ind_1874_Feld_ObjFlaeche25 {
                label
              }
              ind_1882_Feld_ObjVerwaltung26 {
                label
              }
              ind_1884_Feld_ObjPreise27 {
                label
              }
              ind_1890_Feld_ObjAusstattung28 {
                label
              }
              ind_1906_Feld_ObjGeo29 {
                label
              }
              ind_1908_Feld_ObjAusstattung30 {
                label
              }
              ind_1910_Feld_ObjAusstattung31 {
                label
              }
              ind_1912_Feld_ObjFlaeche32 {
                label
              }
              ind_1960_Feld_ObjFlaeche51 {
                label
              }
              ind_1962_Feld_ObjFlaeche52 {
                label
              }
              ind_1970_Feld_ObjPreise56 {
                label
              }
              ind_1974_Feld_ObjPreise57 {
                label
              }
              ind_1976_Feld_ObjAusstattung58 {
                label
              }
              ind_1982_Feld_ObjAusstattung59 {
                label
              }
              ind_1984_Feld_ObjPreise60 {
                label
              }
              ind_1996_Feld_ObjAusstattung61 {
                label
              }
              ind_2028_Feld_ObjAusstattung65 {
                label
              }
              ind_2046_Feld_ObjAusstattung69 {
                label
              }
              ind_2022_Feld_ObjAusstattung62 {
                label
              }
              ind_2026_Feld_ObjAusstattung64 {
                label
              }
              ind_2024_Feld_ObjAusstattung63 {
                label
              }
              ind_2090_Feld_ObjAusstattung74 {
                label
              }
              ind_2118_Feld_ObjTech76 {
                label
              }
              ind_2120_Feld_ObjAusstattung77 {
                label
              }
              ind_2122_Feld_ObjAusstattung78 {
                label
              }
              ind_2124_Feld_ObjAusstattung79 {
                label
              }
              ind_2126_Feld_ObjAusstattung80 {
                label
              }
              ind_2132_Feld_ObjAusstattung82 {
                label
              }
              ind_2134_Feld_ObjAusstattung83 {
                label
              }
              ind_2136_Feld_ObjAusstattung83 {
                label
              }
              ind_2138_Feld_ObjAusstattung84 {
                label
              }
              ind_2140_Feld_ObjZustand85 {
                label
              }
              ind_2142_Feld_ObjTech86 {
                label
              }
              ind_2300_Feld_ObjIndividualCategoryFields112 {
                label
              }
              ind_2260_Feld_ObjIndividualCategoryFields96 {
                label
              }
              ind_2258_Feld_ObjIndividualCategoryFields95 {
                label
              }
              ind_2256_Feld_ObjIndividualCategoryFields94 {
                label
              }
              ind_2274_Feld_ObjIndividualCategoryFields103 {
                label
              }
              ind_2252_Feld_ObjIndividualCategoryFields92 {
                label
              }
              ind_2250_Feld_ObjIndividualCategoryFields91 {
                label
              }
              ind_2248_Feld_ObjIndividualCategoryFields90 {
                label
              }
              ind_2272_Feld_ObjIndividualCategoryFields102 {
                label
              }
              ind_2270_Feld_ObjIndividualCategoryFields101 {
                label
              }
              ind_2268_Feld_ObjIndividualCategoryFields100 {
                label
              }
              ind_2266_Feld_ObjIndividualCategoryFields99 {
                label
              }
              ind_2264_Feld_ObjIndividualCategoryFields98 {
                label
              }
              ind_2290_Feld_ObjIndividualCategoryFields111 {
                label
              }
              ind_2288_Feld_ObjIndividualCategoryFields110 {
                label
              }
              ind_2286_Feld_ObjIndividualCategoryFields109 {
                label
              }
              ind_2296_Feld_ObjIndividualCategoryFields114 {
                label
              }
              ind_2294_Feld_ObjIndividualCategoryFields113 {
                label
              }
              ind_2292_Feld_ObjIndividualCategoryFields112 {
                label
              }
              jahrLetzteModernisierung {
                label
              }
              kabel_sat_tv {
                label
              }
              kaltmiete {
                label
              }
              kamin {
                label
              }
              kaufpreis {
                label
              }
              kaution {
                label
              }
              kinderfreundlich {
                label
              }
              kueche {
                label
                permittedvalues {
                  pantry
                  ebk
                  offen
                  indMulti1856Select6204
                  indMulti2090Select7708
                }
              }
              label
              ladenflaeche {
                label
              }
              lage {
                label
              }
              lagerflaeche {
                label
              }
              land {
                label
                permittedvalues {
                  AUT
                  BEL
                  DEU
                  FRA
                }
              }
              max_mietdauer {
                label
              }
              max_personen {
                label
              }
              miete_pauschal {
                label
              }
              miete_pauschal_pro {
                label
                permittedvalues {
                  T
                  W
                  M
                }
              }
              min_mietdauer {
                label
              }
              moebliert {
                permittedvalues {
                  teil
                  voll
                }
              }
              multiParkingLot {
                label
              }
              mwstSatzAussenCourtage {
                label
              }
              nebenflaeche {
                label
              }
              nebenkosten {
                label
              }
              nettokaltmiete {
                label
              }
              nichtraucher {
                label
                permittedvalues {
                  ja
                  nein
                }
              }
              nutzflaeche {
                label
              }
              nutzungsart {
                label
                permittedvalues {
                  wohnen
                  gewerbe
                  anlage
                  waz
                }
              }
              objektart {
                label
                permittedvalues {
                  zimmer
                  haus
                  wohnung
                  grundstueck
                  buero_praxen
                  einzelhandel
                  gastgewerbe
                  hallen_lager_prod
                  land_und_forstwirtschaft
                  freizeitimmbilien_gewerblich
                  sonstige
                  parken
                  zinshaus_renditeobjekt
                  ind_Schl_2979
                }
              }
              objektbeschreibung {
                label
              }
              objektnr_extern {
                label
              }
              objekttitel {
                label
              }
              objekttyp {
                label
                permittedvalues {
                  stellplatz
                  carport
                  doppelgarage
                  duplex
                  tiefgarage
                  bootsliegeplatz
                  rohdachboden
                  seeliegenschaft
                  jagdrevier
                  parkplatz_strom
                  hausbau_zweifamilienhaus
                  tiefgaragenstellplatz
                  parken_parkhaus
                  einzelgarage
                  freizeitanlage
                  einraumlokal
                  raucherlokal
                  praxisflaeche
                  gewerbepark
                  buerozentrum
                  bungalow
                  einfamilienhausMitEinliegerwohnung
                  zimmer
                  hochparterre
                  eigentumswohnung
                  blockhaus
                  hausbau_reihenhaus
                  hausbau_mehrfamilienhaus
                  hausbau_landhaus
                  holzhaus
                  fachwerkhaus
                  hausbau_einfamilienhaus
                  doppelhaus
                  hausbau_bungalow
                  hausbau_villa
                  ausstellungsflaeche
                  mehrfamilienhaus
                  industriehalle
                  wohn_und_geschaeftshaus
                  industriehalle_und_freiflaeche
                  geschaeftshaus
                  kuehlhaus
                  buerogebaeude
                  sb_maerkte
                  kuehlregallager
                  einkaufscentren
                  lager_mit_freiflaeche
                  lagerflaeche
                  wohnanlagen
                  lagerhalle
                  verbrauchermaerkte
                  speditionslager
                  industrieanlagen
                  anwesen
                  barbetrieb
                  cafe
                  loft
                  atelier
                  bueroetage
                  buero_und_lager
                  praxisetage
                  praxishaus
                  gewerbezentrum
                  factory_outlet
                  kaufhaus
                  kiosk
                  sb_markt
                  verkaufssflaeche
                  verkaufshalle
                  diskothek
                  gaestehaus
                  gaststaette
                  hotelanwesen
                  hotel_garni
                  restaurant
                  sportanlage
                  vergnuegungsparks_und_center
                  halle
                  lager
                  produktion
                  werkstatt
                  hochregallager
                  service
                  freiflaechen
                  dachgeschoss
                  maisonette
                  loft_studio_atelier
                  penthouse
                  terrassen
                  etage
                  erdgeschoss
                  souterrain
                  reihenhaus
                  reihenend
                  reihenmittel
                  reiheneck
                  doppelhaushaelfte
                  einfamilienhaus
                  stadthaus
                  villa
                  resthof
                  bauernhaus
                  landhaus
                  schloss
                  zweifamilienhaus
                  ferienhaus
                  berghuette
                  chalet
                  strandhaus
                  wohnen
                  gewerbe
                  industrie
                  land_forstwirtschaft
                  freizeit
                  gemischt
                  sondernutzung
                  bueroflaeche
                  buerohaus
                  praxis
                  ladenlokal
                  einzelhandelsladen
                  verbrauchermarkt
                  einkaufszentrum
                  gastronomie
                  gastronomie_und_wohnung
                  pensionen
                  hotels
                  weitere_beherbergungsbetriebe
                  landwirtschaftliche_betriebe
                  bauernhof
                  aussiedlerhof
                  viehwirtschaft
                  jagd_und_forstwirtschaft
                  teich_und_fischwirtschaft
                  scheunen
                  reiterhoefe
                  sonstige_landwirtschaftsimmobilien
                  parkhaus
                  tankstelle
                  sonstige
                  gewerbeeinheit
                  gewerbeanwesen
                  wohnanlage
                  shared_office
                  coworking
                  besondereImmobilie
                  ind_Schl_4323
                  ind_Schl_2929
                  ind_Schl_4325
                }
              }
              ort {
                label
              }
              pacht {
                label
              }
              placeholderEnergy {
                label
              }
              plz {
                label
              }
              provisionsfrei {
                label
              }
              rampe {
                label
              }
              regionaler_zusatz {
                label
              }
              sauna {
                label
              }
              scout_region {
                label
              }
              seniorengerecht {
                label
              }
              sonstige_angaben {
                label
              }
              strasse {
                label
              }
              swimmingpool {
                label
              }
              teekueche {
                label
              }
              teilbar_ab {
                label
              }
              terrasse {
                label
              }
              unterkellert {
                label
                permittedvalues {
                  JA
                  NEIN
                  TEIL
                }
              }
              verfuegbar_ab {
                label
              }
              verkaufsflaeche {
                label
              }
              vermarktungsart {
                label
                permittedvalues {
                  kauf
                  miete
                  pacht
                  erbpacht
                }
              }
              vermietbare_flaeche {
                label
              }
              vermietet {
                label
              }
              waehrung {
                label
                permittedvalues {
                  EUR
                }
              }
              warmmiete {
                label
              }
              warmwasserEnthalten {
                label
              }
              wasch_trockenraum {
                label
              }
              wg_geeignet {
                label
              }
              wintergarten {
                label
              }
              wohnflaeche {
                label
              }
              wohnungskategorie {
                label
                permittedvalues {
                  A
                  B
                  C
                  D
                }
              }
              wohnungsnr {
                label
              }
              x_fache {
                label
              }
              x_fache_soll {
                label
              }
              zeit_bus {
                label
              }
              zustand {
                label
                permittedvalues {
                  abrissobjekt
                  baufaellig
                  entkernt
                  projektiert
                  neuwertig
                  vollstaendig_renoviert
                  renovierungsbeduerftig
                  modernisiert
                  rohbau
                  erstbezug
                  gepflegt
                  saniert
                  nach_vereinbarung
                  erstbezug_nach_sanierung
                }
              }
              zzgl_mehrwertsteuer {
                label
                permittedvalues {
                  _0
                  _1
                }
              }
              ind_1878_Feld_ObjVerwaltung25 {
                label
              }
              mieteinnahmen_pro_monat {
                label
              }
              ind_2334_Feld_ObjVerwaltung121 {
                label
              }
              ind_2320_Feld_ObjVerwaltung114 {
                label
              }
              ind_2322_Feld_ObjVerwaltung115 {
                label
              }
              ind_2324_Feld_ObjVerwaltung116 {
                label
              }
              ind_2326_Feld_ObjVerwaltung117 {
                label
              }
              ind_2328_Feld_ObjVerwaltung118 {
                label
              }
              ind_2330_Feld_ObjVerwaltung119 {
                label
              }
              ind_2332_Feld_ObjVerwaltung120 {
                label
              }     
            }
          }
        }
      }
    }
  `);
    return onOfficeFields.allFieldsJson.edges.map((r) => r.node.elements)[0];
}
