import OnOfficeEstate from "./estate";

enum SortCriteria {
  PurchasePrice = "Kaufpreis",
  ColdRent = "Kaltmiete",
  LivingArea = "Wohnfläche",
  PropertyArea = "Grundstücksfläche",
  NumberOfRooms = "Zimmeranzahl",
  NewOffers = "Neuste Angebote",   // new offers
}

enum SortDirection {
  Ascendent = " aufsteigend",
  Descendent = " absteigend",
}

export enum SortKey {
  PurchasePriceAsc = 1,
  PurchasePriceDesc = 2,
  ColdRentAsc = 3,
  ColdRentDesc = 4,
  LivingAreaAsc = 5,
  LivingAreaDesc = 6,
  PropertyAreaAsc = 7,
  PropertyAreaDesc = 8,
  NumberOfRoomsAsc = 9,
  NumberOfRoomsDesc = 10,
  NewOffersAsc = 11,    // new offers
  NewOffersDesc = 12,   // new offers
}

export interface SortOption {
  value: string;
  key: SortKey;
}

const sortOptions: SortOption[] = [
  // new offers
  {
    key: SortKey.NewOffersAsc,
    value: SortCriteria.NewOffers + SortDirection.Ascendent,
  },
  {
    key: SortKey.NewOffersDesc,
    value: SortCriteria.NewOffers + SortDirection.Descendent,
  },
  {
    key: SortKey.PurchasePriceAsc,
    value: SortCriteria.PurchasePrice + SortDirection.Ascendent,
  },
  {
    key: SortKey.PurchasePriceDesc,
    value: SortCriteria.PurchasePrice + SortDirection.Descendent,
  },
  {
    key: SortKey.ColdRentAsc,
    value: SortCriteria.ColdRent + SortDirection.Ascendent,
  },
  {
    key: SortKey.ColdRentDesc,
    value: SortCriteria.ColdRent + SortDirection.Descendent,
  },
  {
    key: SortKey.LivingAreaAsc,
    value: SortCriteria.LivingArea + SortDirection.Ascendent,
  },
  {
    key: SortKey.LivingAreaDesc,
    value: SortCriteria.LivingArea + SortDirection.Descendent,
  },
  {
    key: SortKey.PropertyAreaAsc,
    value: SortCriteria.PropertyArea + SortDirection.Ascendent,
  },
  {
    key: SortKey.PropertyAreaDesc,
    value: SortCriteria.PropertyArea + SortDirection.Descendent,
  },
  {
    key: SortKey.NumberOfRoomsAsc,
    value: SortCriteria.NumberOfRooms + SortDirection.Ascendent,
  },
  {
    key: SortKey.NumberOfRoomsDesc,
    value: SortCriteria.NumberOfRooms + SortDirection.Descendent,
  },
];

// Safari does not support all date formats !
const safariDate = (date: string) => {
  // "2022-03-25" => [2022, 3, 25]
  const dateNumbers = date.split("-").map((n) => Number(n));
  const fullYear = dateNumbers[0],
      monthIndex = dateNumbers[1] - 1,
      day = dateNumbers[2];
  return new Date(fullYear, monthIndex, day);
};

// return OfficeEstate New Offer Label
export const getNewOffersOrderLabel = (estate : OnOfficeEstate) => {
  const newOffersOrderIndexArray = ["Verkauft","Vermietet","Reserviert","","Neu"]
  const newOffersOrderIndex = getNewOffersOrderIndex(estate);
  return  newOffersOrderIndexArray[newOffersOrderIndex]
}

const getNewOffersOrderIndex = (estate : OnOfficeEstate) => {
  return estate.ind_2038_Feld_ObjTech66 === "ind_Schl_3047"
      ? 0 // "Verkauft"
      : estate.ind_2038_Feld_ObjTech66 === "ind_Schl_3045"
      ? 1 //"Vermietet"
      : estate.ind_2038_Feld_ObjTech66 === "ind_Schl_3027"
      ? 2 //"Reserviert"
      : safariDate(estate.ind_2092_Feld_ObjTech75).getTime() >=
              new Date().setDate(new Date().getDate() - 28) &&
              estate.ind_2038_Feld_ObjTech66 !== "ind_Schl_3023"
      ? 4 // "Neu"
      : 3 // ""; new but older then 28 Days
}

export const sortEstates = (
  estate: OnOfficeEstate[],
  optionKey: SortKey = SortKey.PurchasePriceDesc
) => {
  switch (optionKey) {
    case SortKey.PurchasePriceAsc:
      return estate.sort((a, b) => {
        return Number(a.kaufpreis) - Number(b.kaufpreis);
      });
    case SortKey.PurchasePriceDesc:
      return estate.sort((a, b) => {
        return Number(b.kaufpreis) - Number(a.kaufpreis);
      });
    case SortKey.ColdRentAsc:
      return estate.sort((a, b) => {
        return Number(a.kaltmiete) - Number(b.kaltmiete);
      });
    case SortKey.ColdRentDesc:
      return estate.sort((a, b) => {
        return Number(b.kaltmiete) - Number(a.kaltmiete);
      });
    case SortKey.LivingAreaAsc:
      return estate.sort((a, b) => {
        return Number(a.wohnflaeche) - Number(b.wohnflaeche);
      });
    case SortKey.LivingAreaDesc:
      return estate.sort((a, b) => {
        return Number(b.wohnflaeche) - Number(a.wohnflaeche);
      });
    case SortKey.PropertyAreaAsc:
      return estate.sort((a, b) => {
        return Number(a.grundstuecksflaeche) - Number(b.grundstuecksflaeche);
      });
    case SortKey.PropertyAreaDesc:
      return estate.sort((a, b) => {
        return Number(b.grundstuecksflaeche) - Number(a.grundstuecksflaeche);
      });
    case SortKey.NumberOfRoomsAsc:
      return estate.sort((a, b) => {
        return Number(a.anzahl_zimmer) - Number(b.anzahl_zimmer);
      });
    case SortKey.NumberOfRoomsDesc:
      return estate.sort((a, b) => {
        return Number(b.anzahl_zimmer) - Number(a.anzahl_zimmer);
      });
    case SortKey.NewOffersAsc:
      return estate.sort((a, b) => {
        return Number(getNewOffersOrderIndex(a)) - Number(getNewOffersOrderIndex(b));
      });
    case SortKey.NewOffersDesc:
      return estate.sort((a, b) => {
        return Number(getNewOffersOrderIndex(b)) - Number(getNewOffersOrderIndex(a));
      });
    default:
      return estate.sort((a, b) => {
        return Number(b.kaufpreis) - Number(a.kaufpreis);
      });
  }
};

export default sortOptions;
