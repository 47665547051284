import {graphql, useStaticQuery} from "gatsby";

/**
 * Following fields are null
 endenergiebedarfStrom
 endenergiebedarfWaerme
 endenergieverbrauchStrom
 endenergieverbrauchWaerme
 */
export default function OnOfficeEstatesQuery() {
    const onOfficeEstates = useStaticQuery(graphql`
    query OnOfficeEstatesQuery {
      allEstatesJson {
        edges {
          node {
            elements {
              abstellraum
              anzahl_badezimmer
              anzahl_balkone
              anzahl_betten
              anzahl_schlafzimmer
              anzahl_sep_wc
              anzahl_tagungsraeume
              anzahl_terrassen
              anzahl_wohneinheiten
              anzahl_zimmer
              ausricht_balkon_terrasse
              aussen_courtage
              ausstatt_beschr
              ausstattungsqualitaet
              balkon
              barrierefrei
              baujahr
              bauphase
              bauweise
              bebaubar_mit
              bebaubar_nach
              befeuerung
              betreutes_wohnen
              boden
              bueroflaeche
              dachform
              denkmalgeschuetzt
              distanz_autobahn
              distanz_bus
              distanz_fernbahnhof
              distanz_flughafen
              distanz_grundschule
              distanz_gymnasium
              distanz_kindergarten
              distanz_realschule
              distanz_us_bahn
              distanz_zentrum
              dv_verkabelung
              einliegerwohnung
              endenergiebedarf
              endenergiebedarfStrom
              endenergiebedarfWaerme
              endenergieverbrauchStrom
              endenergieverbrauchWaerme
              energieausweis_gueltig_bis
              energieausweisBaujahr
              energieausweistyp
              energietraeger
              energieverbrauchskennwert
              energyClass
              erbpacht
              erschliessung
              erschliessungskosten
              erstellt_am
              etage
              fahrradraum
              fahrstuhl
              gaesteWc
              gardenhouse
              gartennutzung
              gastroflaeche
              geaendert_am
              gesamtflaeche
              gewerbeflaeche
              gewerbliche_nutzung
              gfz
              grundstuecksflaeche
              grz
              hausgeld
              hausnummer
              haustiere
              heizkosten
              heizkosten_in_nebenkosten
              heizungsart
              Id
              ind_1810_Feld_ObjAusstattung0
              ind_1812_Feld_ObjAusstattung1
              ind_1852_Feld_ObjAusstattung17
              ind_1856_Feld_ObjAusstattung18
              ind_1860_Feld_ObjAusstattung20
              ind_1862_Feld_ObjAusstattung20
              ind_1864_Feld_ObjPreise21
              ind_1866_Feld_ObjPreise22
              ind_1868_Feld_ObjPreise23
              ind_1874_Feld_ObjFlaeche25
              ind_1878_Feld_ObjVerwaltung25
              ind_1882_Feld_ObjVerwaltung26
              ind_1884_Feld_ObjPreise27
              ind_1890_Feld_ObjAusstattung28
              ind_1906_Feld_ObjGeo29
              ind_1908_Feld_ObjAusstattung30
              ind_1910_Feld_ObjAusstattung31
              ind_1912_Feld_ObjFlaeche32
              ind_1960_Feld_ObjFlaeche51
              ind_1962_Feld_ObjFlaeche52
              ind_1970_Feld_ObjPreise56
              ind_1974_Feld_ObjPreise57
              ind_1976_Feld_ObjAusstattung58
              ind_1982_Feld_ObjAusstattung59
              ind_1984_Feld_ObjPreise60
              ind_1996_Feld_ObjAusstattung61
              ind_2022_Feld_ObjAusstattung62
              ind_2024_Feld_ObjAusstattung63
              ind_2026_Feld_ObjAusstattung64
              ind_2028_Feld_ObjAusstattung65
              ind_2038_Feld_ObjTech66
              ind_2042_Feld_ObjTech67
              ind_2044_Feld_ObjTech68
              ind_2046_Feld_ObjAusstattung69
              ind_2052_Feld_ObjAusstattung70
              ind_2090_Feld_ObjAusstattung74
              ind_2092_Feld_ObjTech75
              ind_2118_Feld_ObjTech76
              ind_2120_Feld_ObjAusstattung77
              ind_2122_Feld_ObjAusstattung78
              ind_2124_Feld_ObjAusstattung79
              ind_2126_Feld_ObjAusstattung80
              ind_2132_Feld_ObjAusstattung82
              ind_2134_Feld_ObjAusstattung83
              ind_2136_Feld_ObjAusstattung83
              ind_2138_Feld_ObjAusstattung84
              ind_2140_Feld_ObjZustand85
              ind_2142_Feld_ObjTech86
              ind_2300_Feld_ObjIndividualCategoryFields112
              ind_2260_Feld_ObjIndividualCategoryFields96
              ind_2258_Feld_ObjIndividualCategoryFields95
              ind_2256_Feld_ObjIndividualCategoryFields94
              ind_2274_Feld_ObjIndividualCategoryFields103
              ind_2252_Feld_ObjIndividualCategoryFields92
              ind_2250_Feld_ObjIndividualCategoryFields91
              ind_2248_Feld_ObjIndividualCategoryFields90
              ind_2272_Feld_ObjIndividualCategoryFields102
              ind_2270_Feld_ObjIndividualCategoryFields101
              ind_2268_Feld_ObjIndividualCategoryFields100
              ind_2266_Feld_ObjIndividualCategoryFields99
              ind_2264_Feld_ObjIndividualCategoryFields98
              ind_2290_Feld_ObjIndividualCategoryFields111
              ind_2288_Feld_ObjIndividualCategoryFields110
              ind_2286_Feld_ObjIndividualCategoryFields109
              ind_2296_Feld_ObjIndividualCategoryFields114
              ind_2294_Feld_ObjIndividualCategoryFields113
              ind_2292_Feld_ObjIndividualCategoryFields112
              ind_2334_Feld_ObjVerwaltung121
              ind_2320_Feld_ObjVerwaltung114
              ind_2322_Feld_ObjVerwaltung115
              ind_2324_Feld_ObjVerwaltung116
              ind_2326_Feld_ObjVerwaltung117
              ind_2328_Feld_ObjVerwaltung118
              ind_2330_Feld_ObjVerwaltung119
              ind_2332_Feld_ObjVerwaltung120
              jahrLetzteModernisierung
              kabel_sat_tv
              kaltmiete
              kamin
              kaufpreis
              kaution
              kinderfreundlich
              kueche
              ladenflaeche
              lage
              lagerflaeche
              land
              max_mietdauer
              max_personen
              miete_pauschal
              miete_pauschal_pro
              mieteinnahmen_pro_monat
              min_mietdauer
              moebliert
              multiParkingLot {
                carport {
                  Count
                  Price
                  MarketingType
                }
                duplex {
                  Count
                  Price
                  MarketingType
                }
                garage {
                  Count
                  Price
                  MarketingType
                }
                multiStoryGarage {
                  Count
                  Price
                  MarketingType
                }
                otherParkingLot {
                  Count
                  Price
                  MarketingType
                }
                parkingSpace {
                  Count
                  Price
                  MarketingType
                }
                undergroundGarage {
                  Count
                  Price
                  MarketingType
                }
              }
              mwstSatzAussenCourtage
              nebenflaeche
              nebenkosten
              nettokaltmiete
              nichtraucher
              nutzflaeche
              nutzungsart
              objektadresse_freigeben_expose
              objektart
              objektbeschreibung
              objektnr_extern
              objekttitel
              objekttyp
              ort
              pacht
              placeholderEnergy
              plz
              provisionsfrei
              preisAufAnfrage
              rampe
              regionaler_zusatz
              reserviert
              sauna
              scout_region
              seniorengerecht
              sonstige_angaben
              status
              strasse
              swimmingpool
              teekueche
              teilbar_ab
              terrasse
              unterkellert
              verfuegbar_ab
              verkaufsflaeche
              verkauft
              vermarktungsart
              vermietbare_flaeche
              vermietet
              veroeffentlichen
              waehrung
              warmmiete
              warmwasserEnthalten
              wasch_trockenraum
              wg_geeignet
              wintergarten
              wohnflaeche
              wohnungskategorie
              wohnungsnr
              x_fache
              x_fache_soll
              zeit_bus
              zustand
              zzgl_mehrwertsteuer
            }
          }
        }
      }
    }
  `);
    return onOfficeEstates.allEstatesJson.edges
        .map((r) => r.node.elements)
        .filter((obj) => !obj.ausstatt_beschr.includes("??"));
}
